import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
// import FacBase from "./pages/facultybase/facultyBase";
import Myfaculty from "./pages/Myfaculty/Myfaculty";
import Mystudents from "./pages/Mystudents/Mystudents";
import LoginPage from "./tushar/LoginPage";
// import "./pages/facultybase/Base.css"
import "./pages/navigation/navigation.css"
import Header from "./pages/Header/Header";
import NoDues from "./pages/NoDues/noDues";
import ApprovedLeaves from "./pages/facultybase/ApprovedLeaves";
import PendingLeaveApprovals from "./pages/facultybase/PendingLeaves";
import Dashboard from "./pages/Dashboard";
import LCgen from "./pages/NoDues/LCGen";
import GeneratedLc from "./pages/NoDues/GeneratedLC";
import LCgenIssued from "./pages/NoDues/LcGenCompleted";
// import MyVisitors from "./pages/MyVisitors/MyVisitors";



function App() {
  const [token, setToken] = useState("");

  const handleLogin = (newToken) => {
    // Set the token in the state when the user logs in
    setToken(newToken);
    window.localStorage.setItem("token", newToken);
  };

  useEffect(() => {
    const storedToken = window.localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, [setToken]);

  return (
    <>
    {
      localStorage.getItem('token') != undefined && window.location.pathname!='/generatedlc' ?<Header />:<></>
    }
      
      <Router>
      <div>
        {/* Pass the handleLogin function as a prop to the Register component */}
        <Routes>
          
          {localStorage.getItem('token') != undefined ? (
            <>
              <Route
                path="/approvedLeaves"
                element={<ApprovedLeaves handleLogin={handleLogin} token={token} />}
              />
              <Route
                path="/"
                element={<Dashboard handleLogin={handleLogin} token={token} />}
              />
              <Route
                path="/dashboard"
                element={<Dashboard handleLogin={handleLogin} token={token} />}
              />
              <Route
                path="/pendingApproval"
                element={<PendingLeaveApprovals handleLogin={handleLogin} token={token} />}
              />              
              <Route
                path="/myFaculty"
                element={<Myfaculty handleLogin={handleLogin} token={token} />}
              />

              <Route
                path="/lcGen"
                element={<LCgen handleLogin={handleLogin} token={token} />}
              />

              <Route
                path="/issedLC"
                element={<LCgenIssued handleLogin={handleLogin} token={token} />}
              />              

              <Route
                path="/generatedLc"
                element={<GeneratedLc handleLogin={handleLogin} token={token} />}
              />              
              
              <Route
                path="/myStudents"
                element={<Mystudents handleLogin={handleLogin} token={token} />}
              />
              <Route
                path="/noDues"
                element={<NoDues handleLogin={handleLogin} token={token} />}
              />
            <Route path="/myStudents" element={<Mystudents />} />

            </>
          ) : (
            <Route
            path="/*"
            element={<LoginPage handleLogin={handleLogin} token={token} />}
          />
       )}

        </Routes>
      </div>
    </Router>
    </>
   
  );
}

export default App;
