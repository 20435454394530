import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Navigation from "../navigation/navigation";

const Mystudents = ({ token }) => {
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const storedToken = localStorage.getItem("token");
  console.log("received Token : ", token);

  async function fetchMyStudents() {
    try {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/myStudents`,
        { headers:{
            Authorization: `${storedToken}`,
        } }
      );
      console.log("Student Response : ", response);
      if (response.status === 200) {
        setStudents(response.data.StudentList);
        setTotal(response.data.StudentList.length);
      }
    } catch (error) {
      console.error("Error fetching student data:", error.message);
    }
  }

  useEffect(() => {
    fetchMyStudents();
  }, []);

  const displayItem = students
    .filter((student) =>
      search
        ? student.full_name.toLowerCase().includes(search.toLowerCase())
        : true
    )
    .map((student) => (
      <tr key={student.uid}>
        <td>{student.gr_number}</td>
        <td>{student.stud_clg_id}</td>
        <td>{student.name}</td>
        <td>{student.bname}</td>
        <td>{student.programm_name}</td>
        <td>
          {/* Add a link to the detailed application here */}
          <a href={`#`}>View</a>
        </td>
      </tr>
    ));

  return (
    <div className="container">
      <div className="card mt-3" style={{width: "100%",flexWrap:"wrap",padding:'20px',gap:'10px'}}>
        <h4 >Student Admission Details </h4>
        <h5 class="card-title">Total Applications: {total}</h5>
        
        <div className="table-responsive" style={{width:'100%'}}>
            <table className="table table-hover m-3">
                <thead>
                  <tr>
                    <th>Gr No.</th>
                    <th>College ID</th>
                    <th>Full Name</th>
                    <th>Branch</th>
                    <th>Selected Program</th>
                    <th>Detailed Application</th>
                  </tr>
                </thead>
                <tbody>{displayItem}</tbody>
            </table>
        </div>

      </div>
    </div>   
  );
};

export default Mystudents;
