import axios from "axios";
import React, { Component } from "react";

class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            facultyCount:0,
            studentCount:0,
            leaveCount:0,
            noDues:0
        }
    }

    componentDidMount=()=>{
        this.fetchDashnoardData();
    }

    fetchDashnoardData=async()=>{
            try {
              
              const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/getDashBoaedInfo`,
                { headers:{
                    Authorization: `${localStorage.getItem('token')}`,
                } }
              );
        
              this.setState({facultyCount:response.data.presentCount});
              this.setState({studentCount:response.data.StudentCount});
              this.setState({leaveCount:response.data.LeavesCount});
              this.setState({noDues:response.data.noDues});
        
            } catch (error) {
              console.log("Error:", error);
            }
    }

    render(){
        const {facultyCount, studentCount, leaveCount, noDues} = this.state;

        return(
            <div className="container">
                <div className="row d-flex justify-content-around mt-3">
                    <div className="card col-lg-4 m-3" style={{padding:'1rem'}}>
                        <h4>Total Faculty:</h4>
                        <h5>{facultyCount}</h5>
                    </div>

                    <div className="card col-lg-4 m-3" style={{padding:'1rem'}}>
                        <h4>Total Student:</h4>
                        <h5>{studentCount}</h5>
                    </div>

                    <div className="card col-lg-4 m-3" style={{padding:'1rem'}}>
                        <h4>Total Pending Leave Approvls:</h4>
                        <h5>{leaveCount}</h5>
                    </div>

                    <div className="card col-lg-4 m-3" style={{padding:'1rem'}}>
                        <h4>Total No Dues Applications:</h4>
                        <h5>{noDues}</h5>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Dashboard;