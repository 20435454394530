import axios from "axios";
import React, { Component } from "react";

class LCgen extends Component{
    constructor(props){
        super(props);
        this.state = {
            noDues:[],
            message:'',
            remark:''
        }
    }

    componentDidMount=()=>{
        this.handleGetNoDuesApp();
    }

    handleGetNoDuesApp=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}/getAllSignedGenNoDues`,{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          if(Number(response.data.lcFlag)!=0){
            this.setState({noDues:response.data.data});
          }
          else{
            this.setState({message:'There are no applications'});
          }
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
    }

    handelViewDoc=async(e)=>{
      window.open(`${process.env.REACT_APP_UPLOAD_URL}/viewDoc/${e}`,'_blank')
  }

  handleClickGenerated=(id)=>{
    window.open(`/generatedlc?id=${id}`,'_blank');
  }
  
  handleIssuedClick = async(id) =>{
    
    axios.post(`${process.env.REACT_APP_BASE_URL}/updateIssuedStatus`,{id:id},{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          alert(response.data.message)
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
  }
    render(){
      const {noDues} = this.state;
      let noDuesItems = []

      if(noDues.length>0){
        noDues.forEach(e=>{
          let remarkItems = []

          {
            (e.remarks!=null  || e.remarks!=undefined) ?
            <>
            {e.remarks.length>0 ? 
              JSON.parse(e.remarks).forEach(s=>{
                remarkItems.push(
                  <option>
                    {s.remark}
                  </option>
                )
              })
            :
            <></>}</> : <></>
          }

          noDuesItems.push(
            <div class="card col-lg-4 mt-3 mb-3 g-3">
                    <div class="card-body">
                      <h5>Name: {e.name}</h5>
                      <h6>Category:{e.category}</h6>
                      <h6>Batch: {e.batch}</h6>
                      <h6>Branch: {e.bname}</h6>
                      <h6>Contact: {e.contact}</h6>
                      <h6>email: {e.email}</h6>
                      
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between">Offer Letter: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.offer_admit)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">Fee Receipt: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.fee_receipt)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">ID Card: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.id_photo)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">ID Proof<button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.proof_id)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">Previous LC: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.previous_lc)}>
                                                View
                                            </button></li>
                      </ul>

                      <div className="container btns mt-3 d-flex justify-content-between">
                        <button className="btn btn-primary" onClick={()=>{this.handleClickGenerated(e.id)}}>Generate LC</button>
                        <button className="btn btn-primary" onClick={()=>{this.handleIssuedClick(e.id)}} >Issued</button>
                      </div>
                    </div>
                </div>
          )
          
        })
      }
      
        return(
            <div className="container">
              <div className="row d-flex justify-content-between">
               {noDuesItems}
              </div>
            </div>
        )
    }
}

export default LCgen;