import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Navigation from "../navigation/navigation";

const MyFaculty = ({ token }) => {
  const [facultyList, setFacultyList] = useState([]);
  const [search, setSearch] = useState("");
  const [totalFaculties, setTotalFaculties] = useState(0);
  const [msg, setMsg] = useState("");

  console.log("received Token : ", token);
  const storedToken = localStorage.getItem("token");

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getMyFaculties`,
        { headers:{
          Authorization: `${storedToken}`,
      } }
      );
      console.log();
      setFacultyList(response.data.FacultyList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const filteredFacultyList = facultyList.filter((faculty) =>
  //   search ? faculty.name.toLowerCase().includes(search) : true
  // );

  const total = facultyList.length;

  const facultyItems = facultyList.map((faculty) => (
    <tr key={faculty.faculty_id}>
      <td>{faculty.faculty_clg_id}</td>
      <td>{faculty.name}</td>
      <td>{faculty.role1}</td>
      <td>{faculty.ftname}</td>
      <td>{faculty.depart_name}</td>
      <td>{faculty.contact}</td>
    </tr>
  ));

  return (
    <div className="container">
    <div className="card mt-3" style={{width: "100%",flexWrap:"wrap",padding:'20px',gap:'10px'}}>
      <h4 >Faculty List</h4>
      <h5 class="card-title">Total Faculty: {total}</h5>
      
      <div className="table-responsive" style={{width:'100%'}}>
          <table className="table table-hover m-3">
              <thead>
                <tr>
                  <th>Emp_ID</th>
                  <th>Faculty Name</th>
                  <th>Designation</th>
                  <th>Faculty Type</th>
                  <th>Department</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>{facultyItems}</tbody>
          </table>
      </div>

    </div>
  </div>   
  );
};

export default MyFaculty;
