import axios from "axios";
import React, { Component } from "react";

class NoDues extends Component{
    constructor(props){
        super(props);
        this.state = {
            noDues:[],
            message:'',
            remark:''
        }
    }

    componentDidMount=()=>{
        this.handleGetNoDuesApp();
    }

    handleGetNoDuesApp=()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}/getAllNoDues`,{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          if(Number(response.data.lcFlag)!=0){
            this.setState({noDues:response.data.data});
          }
          else{
            this.setState({message:'There are no applications'});
          }
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
    }

    
    

  handleGetSignedNoDuesApp=()=>{
      axios.get(`${process.env.REACT_APP_BASE_URL}/getAllSignedNoDues`,{ headers: { Authorization: localStorage.getItem('token')}})
    . then((response)=>
      {
        if(Number(response.data.lcFlag)!=0){
          this.setState({noDues:response.data.data});
        }
        else{
        this.setState({noDues:[]});
        this.setState({message:'There are no applications'});
        }
      })
      .catch(err=>
        {
        if(err.response.data.message=='Forbidden: Invalid token')
        {
          alert("Session Has been Expired!.");
          localStorage.clear()
          window.location.reload();
        }
        }
    );
  }

  handleGetRemarkNoDues=()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/getAllRemarkedNoDues`,{ headers: { Authorization: localStorage.getItem('token')}})
  . then((response)=>
    {
      if(Number(response.data.lcFlag)!=0){
        this.setState({noDues:response.data.data});
      }
      else{
        this.setState({noDues:[]});
        this.setState({message:'There are no applications'});
      }
    })
    .catch(err=>
      {
      if(err.response.data.message=='Forbidden: Invalid token')
      {
        alert("Session Has been Expired!.");
        localStorage.clear()
        window.location.reload();
      }
      }
  );
}

    handelViewDoc=async(e)=>{
      window.open(`${process.env.REACT_APP_UPLOAD_URL}/viewDoc/${e}`,'_blank')
  }

  handleRemarkAdd=async(remark,alumni_id)=>{
    axios.post(`${process.env.REACT_APP_BASE_URL}/addRemark`,{alumni_id:alumni_id,remark:remark},{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          if(Number(response.data.success)!=0){
            alert('Remark Has been Added');
            window.location.reload();
          }
    
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
  }

  handleSign = async(alumni_id) =>{
    axios.post(`${process.env.REACT_APP_BASE_URL}/formSign`,{alumni_id:alumni_id},{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          if(Number(response.data.success)!=0){
            alert('Application Has been Signed!')
            window.location.reload();
          }
    
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
  }

    render(){
      const {noDues} = this.state;
      let noDuesItems = []

      if(noDues.length>0){
        noDues.forEach(e=>{
          let remarkItems = []

          {
            (e.remarks!=null  || e.remarks!=undefined) ?
            <>
            {e.remarks.length>0 ? 
              JSON.parse(e.remarks).forEach(s=>{
                remarkItems.push(
                  <option>
                    {s.remark}
                  </option>
                )
              })
            :
            <></>}</> : <></>
          }

          noDuesItems.push(
            <div class="card col-lg-4 mt-3 mb-3 g-3">
                    <div class="card-body">
                      <h5>Name: {e.name}</h5>
                      <h6>Category:{e.category}</h6>
                      <h6>Batch: {e.batch}</h6>
                      <h6>Branch: {e.bname}</h6>
                      <h6>Contact: {e.contact}</h6>
                      <h6>email: {e.email}</h6>
                      
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between">Offer Letter: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.offer_admit)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">Fee Receipt: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.fee_receipt)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">ID Card: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.id_photo)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">Company ID/Salary Slip<button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.proof_id)}>
                                                View
                                            </button></li>
                        <li class="list-group-item d-flex justify-content-between">Previous LC: <button className="btn btn-primary" onClick={()=>this.handelViewDoc(e.previous_lc)}>
                                                View
                                            </button></li>
                      </ul>
                      <select>
                        <option>Remark</option>
                        {remarkItems}
                      </select>

                      {
                        e.app_status==undefined?
                        <div className="buttons mt-3 d-flex justify-content-between">
                        <button className="btn btn-primary" onClick={()=>{this.handleSign(e.id)}}>Sign</button>
                        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#remarkModal">Give Remark</button>

                        <div class="modal fade" id="remarkModal" tabindex="-1" aria-labelledby="remarkModalLabel" aria-hidden="true">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1 class="modal-title fs-5" id="remarkModalLabel">Remark</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <div class="form-floating mb-3">
                                  <input type="text" class="form-control" id="floatingInput" placeholder="" onChange={(e)=>this.setState({remark:e.target.value})}/>
                                  <label for="floatingInput">Remark</label>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={(ed)=>this.handleRemarkAdd(this.state.remark, e.id)}>Save changes</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> : <></>
                      }
                      
                    </div>
                </div>
          )
          
        })
      }
      
        return(
            <div className="container">
              <div className="row d-flex justify-content-between">
                <div className="col-lg-12 mt-3">
                  <div className="btns d-flex w-100 justify-content-between">
                  <button className="btn btn-primary" onClick={()=>this.handleGetRemarkNoDues()}>
                    Remarked Application
                  </button>
                  <button className="btn btn-primary" onClick={()=>this.handleGetSignedNoDuesApp()}>
                    Signed Application
                  </button>
                  </div>
                  
                  
                </div>
               {noDuesItems.length>0 ? noDuesItems :
               <h5>No Applications!</h5>
               }
              </div>
            </div>
        )
    }
}

export default NoDues;