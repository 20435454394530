import { Component } from "react";
import './certificate.css'
import { useSearchParams } from "react-router-dom";
import axios from "axios";

class GeneratedLc extends Component{
    constructor(props){
        super(props);
        this.state = {
            data : {},
            date: ''
        }
    }

    componentDidMount=async()=>{
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('id')

        await this.handleGetNoDuesApp(id)
    }

    handleGetNoDuesApp=async(id)=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}/getAlumniWithID`,{id:id},{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          if(Number(response.data.lcFlag)!=0){
            this.setState({data:response.data.data});
          }
          else{
            this.setState({message:'There are no applications'});
          }
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevState.data && this.state.data) {
        window.print();
        }
      }

    render(){
        const {data,date} = this.state;

        return (
            <>
            <div class="certificate">
                <div class="titles"><h1>LEAVING CERTIFICATE</h1></div>
                <div class="id_number">
                    <h4>I.D. No.: {data.sid}</h4>
                    <h4>General Register No.: {data.grno}</h4>
                    <h4>Serial No.: {data.id}</h4>
                </div>

                <div class="table_content">
                    <table className="table_data">
                        <tr>
                            <td class="headings"><h4>Seat No</h4></td>
                            <td><h4>{data.seat_no}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Academic Year</h4></td>
                            <td><h4>{data.acaademic}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Student Name</h4></td>
                            <td><h4>{data.sname}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Religion</h4></td>
                            <td><h4>{data.religion}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Caste</h4></td>
                            <td><h4>{data.caste}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Nationality</h4></td>
                            <td><h4>{data.nationality}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Place of Birth</h4></td>
                            <td><h4>{data.pob}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Date of Birth</h4></td>
                            <td><h4>{new Date(data.dob).toLocaleDateString('en-GB')}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Date of Birth (in Words)</h4></td>
                            <td><h4>{data.dobw}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Last School/College Attended</h4></td>
                            <td><h4>{data.cattended}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Date of First Admision in College</h4></td>
                            <td><h4>{new Date(data.doa).toLocaleDateString('en-GB')}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Last Class Attended</h4></td>
                            <td><h4>{data.lcattended}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Progress</h4></td>
                            <td><h4>
                            
                            </h4></td>
                        </tr>
                        <tr>
                            <td><h4>Conduct</h4></td>
                            <td><h4>Good</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Date of leaving the College</h4></td>
                            <td><h4>{new Date(data.dol).toLocaleDateString('en-GB')}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Last University Exam Attended</h4></td>
                            <td><h4>{data.lexam}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Since When</h4></td>
                            <td><h4>{new Date(data.swhen).toLocaleDateString('en-GB')}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Reason of Leaving the College</h4></td>
                            <td><h4>{data.rleaving}</h4></td>
                        </tr>
                        <tr>
                            <td><h4>Remark</h4></td>
                            <td><h4>Secured CGPI: {data.remark}</h4></td>
                        </tr>
                    </table>
                </div>

                <div class="para">
                    <p class="certify">
                    Certified that the above information is in accordance with the College Register.
                    </p>

                    <p class="no-change">
                        (No change in any entry in this certificate shall be made except by the authority issuing it and any infrigement of this rule is liable for punishment.)
                    </p>


                    <div class="stamp">
                        <h4 class="font-size">Date: {new Date().toLocaleDateString('en-GB')}</h4>
                        <h4 class="font-size">Prepared By</h4>
                        <h4 class="font-size">PRINCIPAL</h4>
                    </div>

                </div>
            </div>
            </>
        )
    }
}

export default GeneratedLc;