import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./style.css";

const PendingLeaveApprovals = ({ token }) => {
  const [presentCount, setPresentCount] = useState(null);
  const [departId, setDepartId] = useState("");

  const [PendingLeaveApprovals, PetpendingLeaveApprovals] = useState([]);

  console.log("received Token : ", token);
  const storedToken = localStorage.getItem("token");

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const fetchDepartID = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };

      // Fetch depart_id first
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/auth/getDepartId`,
        { headers }
      );

      setDepartId(response.data.depart_id);
      console.log("set Depart Id : ", response.data.depart_id);

      // Add your code that depends on `departId` here, if needed
      // For example, you can call `fetchData()` here after setting `departId`.
      fetchData();
    } catch (error) {
      console.log("Error:", error);
    }
  };

const PetchpendingLeaveApprovals = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/PetpendingLeaveApprovals`,
        { headers:{
          Authorization: `${storedToken}`,
      } }
      );

      // Format the date strings before setting the state
      const formattedLeaves = response.data.pendingList.map((leave) => ({
        ...leave,
        from_date: formatDate(leave.from_date),
        to_date: formatDate(leave.to_date),
      }));

      PetpendingLeaveApprovals(formattedLeaves);
      console.log("approved Leaves : ", formattedLeaves);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const HandleLeaveApprovalDenial = async (app_id,status) => {
    try {
      
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updateLeaveStatus`,
        {
          leave_app_id:app_id,
          status:status
        },
        { headers:{
          Authorization: `${storedToken}`,
      } }
      );

      if(Number(response.data.done)==1){
        alert('Approval Status Updates!');
        window.location.reload();
      }

    } catch (error) {
      console.log("Error:", error);
    }
  };


  const fetchData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };

      // Use the 'token' in your headers for authentication
      // Pass depart_id as a query parameter
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/presentFaculty`,
        {
           headers:{
            Authorization: `${storedToken}`,
        } ,
          params: {
            depart_id: departId,
          },
        }
      );

      console.log("Response Data:", response.data);

      setPresentCount(response.data.presentCount);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  

  useEffect(() => {
    fetchDepartID();
    PetchpendingLeaveApprovals();
  }, [token]); // Trigger the fetchDepartID when the token changes

  return (
    <div className="container">
    <div className="card mt-3" style={{width: "100%",flexWrap:"wrap",padding:'20px',gap:'10px'}}>
      <h4 >Pending Approvals</h4>
      
      <div className="table-responsive" style={{width:'100%'}}>
          <table className="table table-hover m-3">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Faculty Clg Id</th>
                <th>Name</th>
                <th>Leave Type</th>
                <th>From date</th>
                <th>To date</th>
                <th>Reason</th>
                <th>No of days</th>
                <th>Alternate</th>
                <th>Update</th>
              </tr>
          </thead>
            <tbody>
            {PendingLeaveApprovals.map((leave, idx) => (
              <tr key={idx}>
                <th>{idx + 1}</th>
                <td>{leave.clgId}</td>
                <td>{leave.name}</td>
                <td>{leave.lname}</td>
                <td>{leave.from_date}</td>
                <td>{leave.to_date}</td>
                <td>{leave.reason}</td>
                <td>{leave.no_of_days}</td>
                <td>{leave.altname}</td>
                <td className="d-flex">
                  <button className="btn btn-primary" onClick={()=>{HandleLeaveApprovalDenial(leave.leave_app_id,1)}}>Approve</button>
                  <button className="btn btn-danger" onClick={()=>{HandleLeaveApprovalDenial(leave.leave_app_id,2)}}>Deny</button>
                </td>
              </tr>
            ))}
          </tbody>
          </table>
      </div>

    </div>
  </div>   
  );
};

export default PendingLeaveApprovals;