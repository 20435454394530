import React, { useEffect, useState } from "react";
import "./Header.css"
import { BrowserRouter as Router, Link, Navigate } from "react-router-dom";
import logo from "../../images/logo.png"
import axios from "axios";

const Header = () => {
  // Assuming the user_type is stored in the localStorage
  // const userType = parseInt(localStorage.getItem("user_type"));
  const [role, setRole ] = useState(0);

  useEffect(() => {
    // Axios call when the component mounts
    axios.get(`${process.env.REACT_APP_BASE_URL}/role`,{ headers: { Authorization: localStorage.getItem('token')}})
      .then(response => {
        setRole(response.data.role)
        console.log(response.data.role)
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  function handleLogout(){
    localStorage.clear();
    alert('Loged Out');
    window.location.reload();
  }

  return (
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container">
          <a class="navbar-brand" href="#" style={{alignItems:"center",display:"flex"}}>
            <img src={logo} alt="Logo" width="50px" class="d-inline-block align-text-top"/>
            <span class="navbar-brand mb-0 h1">Academate</span>
          </a>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse w-100" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-end">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/dashboard">Dashboard</a>
              </li>

              {
                Number(role)!=2 ?
                <li class="nav-item dropdown">
                  <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Leaves
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="/approvedLeaves">Approved Leaves</a></li>
                    <li><hr class="dropdown-divider"/></li>
                    <li><a class="dropdown-item" href="/pendingApproval">Pending Leave Approvals</a></li>
                  </ul>
                </li>:
                <>
                  <li class="nav-item dropdown">
                    <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Generate LC
                    </a>
                    <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="/lcgen">LCs</a></li>
                    <li><hr class="dropdown-divider"/></li>
                    <li><a class="dropdown-item" href="/issedLc">Issued LCs</a></li>
                  </ul>
                  </li>
                </>
              }
              

              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/noDues">No Dues</a>
              </li>

              {/* <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/visitors">Visitors</a>
              </li> */}

              {
                Number(role)!=2 ?
                <li class="nav-item dropdown">
                <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  List
                </a>
                <ul class="dropdown-menu">
                  {
                    Number(role)==1?
                    <>
                      <li><a class="dropdown-item" href="/myStudents">Students</a></li>
                      <li><hr class="dropdown-divider"/></li>
                    </>:
                    <></>
                  }
                  
                  <li><a class="dropdown-item" href="/myFaculty">Faculty</a></li>
                </ul>
              </li>: <></>
              }
              

              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#" onClick={()=>handleLogout()}>Logout</a>
              </li>
            
            </ul>
          </div>
        </div>
        
    </nav>
  );
};

export default Header;
